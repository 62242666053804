import React from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import './init.css';
import './general.scss';
import MainPage from './pages/main_page';
import CreateContract from './pages/create_contract';
import ThankYou from './pages/thank_you';
import Blank from './pages/blank';
// import Promotion from './components/promotion';
import * as serviceWorker from './serviceWorker';
import * as routes from './constants';


function App() {
  return (
    <BrowserRouter>

      <Routes>

        <Route path={routes.THANK_YOU_PATH} element={<ThankYou />} />

        <Route path={routes.BLANK_PATH} element={<Blank />} />

        <Route path={routes.MAIN_PATH} element={<MainPage />} />

        <Route path={`${routes.CONFIRM_CONTRACT_CREATION_PATH}:userHash`} element={<CreateContract />} />

        <Route path="*" element={<Navigate to={routes.MAIN_PATH} replace />} />

      </Routes>

    </BrowserRouter>
  );
}

const container = document.getElementById("root");

let root;

if (container.hasChildNodes()) {
  root = hydrateRoot(container, <App />);
} else {
  root = createRoot(container);
  root.render(<App />);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
