import React from 'react';
import MainHeader from '../components/main_header';
import MainFooter from '../components/main_footer';
import ChatOverlayButton from '../components/chat_overlay_button';
import { useLocation } from 'react-router-dom'; //Link
import DefaultInput, {SelectOption} from '../utils/default_input';
import {postModel, getPhoneText, setInputCursor, getCurrencyText} from '../utils/functions';
import * as routes from '../constants';
import {DEFAULT_ERROR_MESSAGE} from '../constants';
import LoadingIcon from '../components/loading_icon'
import './main_page.scss';
import WarningMessage from '../utils/warning_message';

import checkIcon from '../images/check_icon.svg';

import main_banner_1 from '../images/main_banner_1.jpg';
import main_banner_1_2x from '../images/main_banner_1_2x.jpg';
import main_banner_1_small from '../images/main_banner_1_small.jpg';
import main_banner_1_small_2x from '../images/main_banner_1_small_2x.jpg';
import main_banner_1_smaller from '../images/main_banner_1_smaller.jpg';
import main_banner_1_smaller_2x from '../images/main_banner_1_smaller_2x.jpg';
import main_banner_1_tiny from '../images/main_banner_1_tiny.jpg';
import main_banner_1_tiny_2x from '../images/main_banner_1_tiny_2x.jpg';

import main_banner_2 from '../images/main_banner_2.jpg';
import main_banner_2_2x from '../images/main_banner_2_2x.jpg';
import main_banner_2_small from '../images/main_banner_2_small.jpg';
import main_banner_2_small_2x from '../images/main_banner_2_small_2x.jpg';
import main_banner_2_smaller from '../images/main_banner_2_smaller.jpg';
import main_banner_2_smaller_2x from '../images/main_banner_2_smaller_2x.jpg';
import main_banner_2_tiny from '../images/main_banner_2_tiny.jpg';
import main_banner_2_tiny_2x from '../images/main_banner_2_tiny_2x.jpg';

import main_banner_3 from '../images/main_banner_3.jpg';
import main_banner_3_2x from '../images/main_banner_3_2x.jpg';
import main_banner_3_small from '../images/main_banner_3_small.jpg';
import main_banner_3_small_2x from '../images/main_banner_3_small_2x.jpg';
import main_banner_3_smaller from '../images/main_banner_3_smaller.jpg';
import main_banner_3_smaller_2x from '../images/main_banner_3_smaller_2x.jpg';
import main_banner_3_tiny from '../images/main_banner_3_tiny.jpg';
import main_banner_3_tiny_2x from '../images/main_banner_3_tiny_2x.jpg';

import price_decorator from '../images/price_decorator.png';
import price_decorator_2x from '../images/price_decorator_2x.png';
import price_decorator_small from '../images/price_decorator_small.png';
import price_decorator_small_2x from '../images/price_decorator_small_2x.png';
import price_decorator_smaller from '../images/price_decorator_smaller.png';
import price_decorator_smaller_2x from '../images/price_decorator_smaller_2x.png';

import main_banner_decorator_1_large from '../images/main_banner_decorator_1_large.png';
import main_banner_decorator_1_large_2x from '../images/main_banner_decorator_1_large_2x.png';
import main_banner_decorator_1 from '../images/main_banner_decorator_1.png';
import main_banner_decorator_1_2x from '../images/main_banner_decorator_1_2x.png';
import main_banner_decorator_1_small from '../images/main_banner_decorator_1_small.png';
import main_banner_decorator_1_small_2x from '../images/main_banner_decorator_1_small_2x.png';
import main_banner_decorator_1_smaller from '../images/main_banner_decorator_1_smaller.png';
import main_banner_decorator_1_smaller_2x from '../images/main_banner_decorator_1_smaller_2x.png';
import main_banner_decorator_1_tiny from '../images/main_banner_decorator_1_tiny.png';
import main_banner_decorator_1_tiny_2x from '../images/main_banner_decorator_1_tiny_2x.png';

import main_banner_decorator_2_large from '../images/main_banner_decorator_2_large.png';
import main_banner_decorator_2_large_2x from '../images/main_banner_decorator_2_large_2x.png';
import main_banner_decorator_2 from '../images/main_banner_decorator_2.png';
import main_banner_decorator_2_2x from '../images/main_banner_decorator_2_2x.png';
import main_banner_decorator_2_small from '../images/main_banner_decorator_2_small.png';
import main_banner_decorator_2_small_2x from '../images/main_banner_decorator_2_small_2x.png';
import main_banner_decorator_2_smaller from '../images/main_banner_decorator_2_smaller.png';
import main_banner_decorator_2_smaller_2x from '../images/main_banner_decorator_2_smaller_2x.png';
import main_banner_decorator_2_tiny from '../images/main_banner_decorator_2_tiny.png';
import main_banner_decorator_2_tiny_2x from '../images/main_banner_decorator_2_tiny_2x.png';

import main_banner_decorator_3 from '../images/main_banner_decorator_3.png';
import main_banner_decorator_3_2x from '../images/main_banner_decorator_3_2x.png';
import main_banner_decorator_3_small from '../images/main_banner_decorator_3_small.png';
import main_banner_decorator_3_small_2x from '../images/main_banner_decorator_3_small_2x.png';
import main_banner_decorator_3_smaller from '../images/main_banner_decorator_3_smaller.png';
import main_banner_decorator_3_smaller_2x from '../images/main_banner_decorator_3_smaller_2x.png';

import about_section_step_1 from '../images/about_section_step_1.jpg';
import about_section_step_1_2x from '../images/about_section_step_1_2x.jpg';

import about_section_step_2 from '../images/about_section_step_2.jpg';
import about_section_step_2_2x from '../images/about_section_step_2_2x.jpg';

import about_section_step_3 from '../images/about_section_step_3.jpg';
import about_section_step_3_2x from '../images/about_section_step_3_2x.jpg';

import about_section_step_4 from '../images/about_section_step_4.jpg';
import about_section_step_4_2x from '../images/about_section_step_4_2x.jpg';

import about_section_step_5 from '../images/about_section_step_5.jpg';
import about_section_step_5_2x from '../images/about_section_step_5_2x.jpg';

import about_section_step_6 from '../images/about_section_step_6.jpg';
import about_section_step_6_2x from '../images/about_section_step_6_2x.jpg';

import about_section_step_image_background from '../images/about_section_step_image_background.svg';
import about_section_step_description_background from '../images/about_section_step_description_background.svg';
import about_section_decorator_1 from '../images/about_section_decorator_1.svg';
import about_section_step_icon_1 from '../images/about_section_step_icon_1.svg';
import about_section_step_icon_2 from '../images/about_section_step_icon_2.svg';
import about_section_step_icon_3 from '../images/about_section_step_icon_3.svg';

import about_section_1_large from '../images/about_section_1_large.jpg';
import about_section_1_large_2x from '../images/about_section_1_large_2x.jpg';
import about_section_1 from '../images/about_section_1.jpg';
import about_section_1_2x from '../images/about_section_1_2x.jpg';
import about_section_1_small from '../images/about_section_1_small.jpg';
import about_section_1_small_2x from '../images/about_section_1_small_2x.jpg';
import about_section_1_very_small from '../images/about_section_1_very_small.jpg';
import about_section_1_very_small_2x from '../images/about_section_1_very_small_2x.jpg';
import about_section_1_tiny from '../images/about_section_1_tiny.jpg';
import about_section_1_tiny_2x from '../images/about_section_1_tiny_2x.jpg';
import about_section_1_very_tiny from '../images/about_section_1_very_tiny.jpg';
import about_section_1_very_tiny_2x from '../images/about_section_1_very_tiny_2x.jpg';

import about_section_2_large from '../images/about_section_2_large.jpg';
import about_section_2_large_2x from '../images/about_section_2_large_2x.jpg';
import about_section_2 from '../images/about_section_2.jpg';
import about_section_2_2x from '../images/about_section_2_2x.jpg';
import about_section_2_small from '../images/about_section_2_small.jpg';
import about_section_2_small_2x from '../images/about_section_2_small_2x.jpg';
import about_section_2_smaller from '../images/about_section_2_smaller.jpg';
import about_section_2_smaller_2x from '../images/about_section_2_smaller_2x.jpg';
import about_section_2_tiny from '../images/about_section_2_tiny.jpg';
import about_section_2_tiny_2x from '../images/about_section_2_tiny_2x.jpg';

import video_section_background_3200 from '../images/video_section_background_3200.jpg';
import video_section_background_2800 from '../images/video_section_background_2800.jpg';
import video_section_background_2400 from '../images/video_section_background_2400.jpg';
import video_section_background_2000 from '../images/video_section_background_2000.jpg';
import video_section_background_1600 from '../images/video_section_background_1600.jpg';
import video_section_background_1400 from '../images/video_section_background_1400.jpg';
import video_section_background_1200 from '../images/video_section_background_1200.jpg';
import video_section_background_1000 from '../images/video_section_background_1000.jpg';
import video_section_background_800 from '../images/video_section_background_800.jpg';
import video_section_background_700 from '../images/video_section_background_700.jpg';
import video_section_background_600 from '../images/video_section_background_600.jpg';
import video_section_background_500 from '../images/video_section_background_500.jpg';
import video_section_background_400 from '../images/video_section_background_400.jpg';
import video_section_background_350 from '../images/video_section_background_350.jpg';
import video_section_background_300 from '../images/video_section_background_300.jpg';


class MainPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      serviceRequest: {
        unit_id: "",
        email: "",
        payment_option: ""
      },
      leadData: {
        name: "",
        email: "",
        phone: "",
        main_objective: "",
        exercise_frequency: "",
        exercise_activity: "",
        payment_option: ""
      },
      promotional_coupon_code: '',
      promotionalCouponApplied: null,
      loadingPromotionalCoupon: false,
      discountFieldMessage: null,
      discountFieldMessageVisible: false,
      showExerciseDescriptionInput: false,
      registrationIsInactive: false,
      sendingContact: false,
      highlights: [],
      contactMessage: "",
      showContactMessage: false,
      contactCompleted: false,
      screenWidth: window.innerWidth,
      scrollPosition: null,
    };

    this.scrollListener = () => this.updateScroll();

    this.mainPageElement = null;
    this.mainPageRef = (element) => {
      if (element == null) {
        this.mainPageElement = null;
      }
      else {
        this.mainPageElement = element;
        this.setState({
          scrollPosition: element.scrollTop
        });

        element.addEventListener("scroll", this.scrollListener);
      }
    };
  }

  async componentDidMount() {
    if (process.env.NODE_ENV === 'production') {
      window.gtag('config', 'G-FPT2FBYVBP', {'page_path': this.props.location.pathname});
    }

    this.resizeListener = () => this.updateSize();
    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  updateScroll() {
    if (this.mainPageElement !== null) {
      this.setState({
        scrollPosition: this.mainPageElement.scrollTop
      });
    }
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;

    const update = {};

    if (name.startsWith('leadData')) {
      const selection = name.split(':');

      name = selection[1];

      if(value && name === 'phone') {
        const initialPosition = target.selectionStart;
        const initialSize = value.length;

        value = value.replace(/\D/g,"")

        target.value = getPhoneText(value);

        let newPosition = (target.value.length - initialSize) + initialPosition;

        if(initialPosition < initialSize) {
          newPosition = initialPosition;
        }

        if(value.length > 2 &&
            (newPosition === 4)) {
          newPosition = 6;
        }

        setInputCursor(target, newPosition);
      }
      if (name === 'exercise_frequency') {
        update.showExerciseDescriptionInput = value.length > 0 && value !== 'Não. Estou sedentário(a) no momento.';
      }

      update.leadData = {...this.state.leadData, [name]: value};
    }
    else if(name === 'promotional_coupon_code') {
      value = value.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLocaleUpperCase().replace(' ', '_');

      update[name] = value;
    }
    else {
      update.serviceRequest = {...this.state.serviceRequest, [name]: value};
    }

    if (name === 'unit_id') {
      update.promotionalCouponApplied = null;
    }

    this.setState(update);
  }

  getUnitOptions() {
    if (this.state.serviceRequest.unit_id.length > 0) {
      return [
        SelectOption('1', 'FYD - Av. Comendador Alfredo Maffei'),
        SelectOption('15', 'FYD - Serasa'),
        SelectOption('0', 'Não sou aluno FYD'),
      ];
    }

    return [
      SelectOption('', 'Selecione sua unidade FYD'),
      SelectOption('1', 'FYD - Av. Comendador Alfredo Maffei'),
      SelectOption('15', 'FYD - Serasa'),
      SelectOption('0', 'Não sou aluno FYD'),
    ];
  }

  getPaymentOptions(checkLeadData) {
    let option1Value = 199.9;
    let option2Value = 72.0;

    if (this.state.promotionalCouponApplied !== null) {
      option1Value = Math.floor(option1Value * (1 - this.state.promotionalCouponApplied.discount_percentage) * 100) / 100;
      option2Value = Math.floor(option2Value * (1 - this.state.promotionalCouponApplied.discount_percentage) * 100) / 100;
    }

    if ((checkLeadData && this.state.leadData.payment_option.length > 0) || (!checkLeadData && this.state.serviceRequest.payment_option.length > 0)) {
      return [
        SelectOption('1', `${getCurrencyText(option1Value)} à vista no PIX`),
        SelectOption('2', `3x de ${getCurrencyText(option2Value)} no cartão de crédito`)
      ];
    }

    return [
      SelectOption('', 'Selecione a forma de pagamento'),
      SelectOption('1', `${getCurrencyText(option1Value)} à vista no PIX`),
      SelectOption('2', `3x de ${getCurrencyText(option2Value)} no cartão de crédito`)
    ];
  }

  getObjectiveOptions() {
    if (this.state.leadData.main_objective.length > 0) {
      return [
        SelectOption('Emagrecimento'),
        SelectOption('Ganho de massa muscular (hipertrofia)'),
        SelectOption('Melhora de performance'),
        SelectOption('Saúde e qualidade de vida'),
      ];
    }

    return [
      SelectOption('', 'Selecione um objetivo principal'),
      SelectOption('Emagrecimento'),
      SelectOption('Ganho de massa muscular (hipertrofia)'),
      SelectOption('Melhora de performance'),
      SelectOption('Saúde e qualidade de vida'),
    ];
  }

  getExerciseFrequencyOptions() {
    if (this.state.leadData.exercise_frequency.length > 0) {
      return [
        SelectOption('Não. Estou sedentário(a) no momento.'),
        SelectOption('Muito pouco. Me exercito algumas vezes por mês.'),
        SelectOption('Me exercito UMA ou DUAS vezes POR SEMANA.'),
        SelectOption('Me exercito entre TRÊS e CINCO vezes POR SEMANA.'),
        SelectOption('Me exercito MAIS DE CINCO vezes POR SEMANA.'),
      ];
    }

    return [
      SelectOption('', 'Selecione uma opção'),
      SelectOption('Não. Estou sedentário(a) no momento.'),
      SelectOption('Muito pouco. Me exercito algumas vezes por mês.'),
      SelectOption('Me exercito UMA ou DUAS vezes POR SEMANA.'),
      SelectOption('Me exercito entre TRÊS e CINCO vezes POR SEMANA.'),
      SelectOption('Me exercito MAIS DE CINCO vezes POR SEMANA.'),
    ];
  }

  async onApplyDiscountCoupon() {
    this.setState({loadingPromotionalCoupon: true});

    let promotional_coupon_code = this.state.promotional_coupon_code;

    let unit_id = null;

    if (!this.state.registrationIsInactive && this.state.serviceRequest.unit_id !== '0') {
      unit_id = parseInt(this.state.serviceRequest.unit_id);
    }

    const data = {
      unit_id,
      code: promotional_coupon_code
    };

    let returnMessage = '';
    let promotionalCouponApplied = null;

    try {
      const response = await postModel(routes.CHECK_DISCOUNT_COUPON_POST_API, data, true);

      if (response) {
        returnMessage = `${100*response.discount_percentage}% de desconto aplicado!`;
        promotionalCouponApplied = response;
      }
    }
    catch(errors) {
      returnMessage = DEFAULT_ERROR_MESSAGE;

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            case 208:
              if (error.message.includes('Invalid coupon code')) {
                returnMessage = 'Código de cupom inválido.'
              }
              else if (error.message.includes('Coupon is not valid yet')) {
                returnMessage = 'Cupom fora do período válido.'
              }
              else if (error.message.includes('Invalid coupon for given service')) {
                returnMessage = 'Cupom inválido.'
              }
              else if (error.message.includes('Coupon is not valid anymore')) {
                returnMessage = 'Cupom expirado.'
              }
              else if (error.message.includes('Coupon is not active')) {
                returnMessage = 'Cupom inativo.'
              }
              else if (error.message.includes('Coupon usage limit reached')) {
                returnMessage = 'Limite de uso do cupom atingido.'
              }

              break;
            case 209:
              returnMessage = 'Sessão do usuário expirada.'
              break;
            default:
          }
        }
      }
    }

    let discountFieldMessageVisible = true;

    if (promotionalCouponApplied !== null) {
      promotional_coupon_code = '';
      discountFieldMessageVisible = false;
    }

    this.setState({
      promotionalCouponApplied,
      loadingPromotionalCoupon: false,
      discountFieldMessage: returnMessage,
      discountFieldMessageVisible,
      promotional_coupon_code
    });
  }

  getFormInputs() {
    let alertMessage = null;

    if (this.state.contactCompleted) {
      alertMessage = (
        <div
          key="main_page:contact_form:error_message"
          className="main-page__alert-text--green"
        >

          <div className="main-page__alert-text__icon-wrapper">
            <i className="fa-solid fa-check main-page__alert-text__icon"></i>
          </div>

          <p className="main-page__alert-text__text">
            {this.state.contactMessage}
          </p>

        </div>
      );
    }
    else if (this.state.registrationIsInactive) {
      alertMessage = (
        <div
          key="main_page:contact_form:user_inactive_message"
          className="main-page__alert-text"
        >

          <div className="main-page__alert-text__icon-wrapper">
            <i className="fas fa-exclamation main-page__alert-text__icon"></i>
          </div>

          <p className="main-page__alert-text__text">
            Infelizmente seu cadastro encontra-se desativado para a unidade selecionada. Sendo assim, pedimos para que preencha o formulário abaixo
            para que possamos entrar em contato e esclarecer qualquer dúvida que tenha ficado sobre o serviço ou para dar continuidade na contratação.
          </p>

        </div>
      );
    }
    // else if (this.state.serviceRequest.unit_id === '0') {
    //   alertMessage = (
    //     <div
    //       key="main_page:contact_form:observation_message"
    //       className="main-page__alert-text"
    //     >
    //
    //       <div className="main-page__alert-text__icon-wrapper">
    //         <i className="fas fa-exclamation main-page__alert-text__icon"></i>
    //       </div>
    //
    //       <p className="main-page__alert-text__text">
    //         Nesta fase inicial de lançamento estamos oferecendo este serviço somente para alunos matriculados
    //         em uma unidade FYD Club. Saiba mais sobre os serviços de nosso espaço
    //         em <a
    //           className="main-page__alert-text__link"
    //           href="https://fydcentrodetreinamento.com.br"
    //           target="_blank"
    //           rel="noopener noreferrer"
    //           onClick={() => {
    //             if (process.env.NODE_ENV === 'production') {
    //               window.gtag('event', 'Click', {
    //                 'event_category' : 'Link',
    //                 'event_label' : 'https://fydcentrodetreinamento.com.br'
    //               });
    //             }
    //           }}
    //         >
    //           https://fydcentrodetreinamento.com.br
    //         </a> e venha fazer parte desta comunidade! Ou, se preferir, preencha o formulário abaixo
    //         que entraremos em contato assim liberarmos a contratação da orientação nutricional para mais pessoas.
    //       </p>
    //
    //     </div>
    //   );
    // }

    return (
      <React.Fragment>

        {(!this.state.registrationIsInactive && !this.state.contactCompleted) &&
          <DefaultInput
            labelClassName="main-page__contact-section__input-label"
            className="main-page__contact-section__input"
            isHighlighted={this.state.highlights.includes('unit_id')}
            name="unit_id"
            label="Já é um aluno FYD? Selecione a unidade em que esteja matriculado:"
            type="select"
            handleInputChange={(event) => this.handleInputChange(event)}
            value={this.state.serviceRequest.unit_id}
            options={this.getUnitOptions()}
            disabled={this.state.sendingContact}
          />
        }

        {(!this.state.registrationIsInactive && this.state.serviceRequest.unit_id !== '0' && !this.state.contactCompleted) &&
          <DefaultInput
            labelClassName="main-page__contact-section__input-label"
            className="main-page__contact-section__input"
            label="E-mail"
            name="email"
            type="email"
            isHighlighted={this.state.highlights.includes('email')}
            placeholder="Digite seu e-mail"
            maxLength="128"
            handleInputChange={(event) => this.handleInputChange(event)}
            value={this.state.serviceRequest.email}
            autoComplete="email"
            disabled={this.state.sendingContact}
          />
        }

        {(!this.state.registrationIsInactive && this.state.serviceRequest.unit_id !== '0' && !this.state.contactCompleted) &&
          <React.Fragment>

            {this.state.serviceRequest.unit_id.length > 0 &&
              <div className="main-page__contact-section__discount-field__container">

                {this.state.loadingPromotionalCoupon ? (
                  <LoadingIcon className="main-page__contact-section__discount-field__loading-icon"/>
                ) : (
                  <React.Fragment>

                    <div className="main-page__contact-section__discount-field__wrapper">

                      <DefaultInput
                        labelClassName="main-page__contact-section__input-label"
                        className="main-page__contact-section__input main-page__contact-section__discount-field__input"
                        name="promotional_coupon_code"
                        label="Cupom de desconto"
                        type="text"
                        placeholder="Código do cupom"
                        maxLength="32"
                        handleInputChange={(event) => this.handleInputChange(event)}
                        value={this.state.promotional_coupon_code}
                        autoComplete="off"
                      />

                      <button
                        className="main-page__contact-section__discount-field__apply-button"
                        disabled={this.state.promotional_coupon_code.length <= 0}
                        onClick={() => this.onApplyDiscountCoupon()}
                      >

                        Aplicar

                      </button>

                    </div>

                    <WarningMessage
                      className="main-page__contact-section__discount-field__result-message"
                      message={this.state.promotionalCouponApplied !== null ? `${100*this.state.promotionalCouponApplied.discount_percentage}% de desconto aplicado!` : this.state.discountFieldMessage}
                      onClose={() => this.setState({discountFieldMessageVisible: false})}
                      visible={this.state.discountFieldMessageVisible || (this.state.promotionalCouponApplied !== null)}
                      background={this.state.promotionalCouponApplied !== null ? '#d6e5ce' : '#fbe1e3'}
                      color={this.state.promotionalCouponApplied !== null ? '#328643' : '#e73d4a'}
                      hideCloseButton={true}
                    />
                  </React.Fragment>
                )}

              </div>
            }

            <DefaultInput
              labelClassName="main-page__contact-section__input-label"
              className="main-page__contact-section__input"
              isHighlighted={this.state.highlights.includes('payment_option')}
              name="payment_option"
              label="Selecione a forma desejada de pagamento:"
              labelMessage={this.state.promotionalCouponApplied !== null ? `Cupom aplicado: ${this.state.promotionalCouponApplied.code}` : null}
              type="select"
              handleInputChange={(event) => this.handleInputChange(event)}
              value={this.state.serviceRequest.payment_option}
              options={this.getPaymentOptions(false)}
              disabled={this.state.sendingContact}
            />

          </React.Fragment>
        }

        {alertMessage}

        {((this.state.registrationIsInactive || this.state.serviceRequest.unit_id === '0') && !this.state.contactCompleted) &&
          <React.Fragment>

            <DefaultInput
              labelClassName="main-page__contact-section__input-label"
              className="main-page__contact-section__input"
              name="leadData:name"
              type="text"
              isHighlighted={this.state.highlights.includes('leadData:name')}
              label="Nome"
              placeholder="Digite seu nome"
              maxLength="128"
              handleInputChange={(event) => this.handleInputChange(event)}
              value={this.state.leadData.name}
              autoComplete="name"
              disabled={this.state.sendingContact}
            />

            <DefaultInput
              labelClassName="main-page__contact-section__input-label"
              className="main-page__contact-section__input"
              name="leadData:email"
              type="email"
              isHighlighted={this.state.highlights.includes('leadData:email')}
              label="E-mail"
              placeholder="Digite seu e-mail"
              maxLength="128"
              handleInputChange={(event) => this.handleInputChange(event)}
              value={this.state.leadData.email}
              autoComplete="email"
              disabled={this.state.sendingContact}
            />

            <DefaultInput
              labelClassName="main-page__contact-section__input-label"
              className="main-page__contact-section__input"
              name="leadData:phone"
              type="tel"
              isHighlighted={this.state.highlights.includes('leadData:phone')}
              label="Telefone"
              placeholder="DDD + Número"
              maxLength="16"
              handleInputChange={(event) => this.handleInputChange(event)}
              value={getPhoneText(this.state.leadData.phone)}
              autoComplete="tel"
              disabled={this.state.sendingContact}
            />

            <DefaultInput
              labelClassName="main-page__contact-section__input-label"
              className="main-page__contact-section__input"
              isHighlighted={this.state.highlights.includes('leadData:main_objective')}
              name="leadData:main_objective"
              label="O que você busca com a contratação da orientação nutricional?"
              type="select"
              handleInputChange={(event) => this.handleInputChange(event)}
              value={this.state.leadData.main_objective}
              options={this.getObjectiveOptions()}
              disabled={this.state.sendingContact}
            />

            <DefaultInput
              labelClassName="main-page__contact-section__input-label"
              className="main-page__contact-section__input"
              isHighlighted={this.state.highlights.includes('leadData:exercise_frequency')}
              name="leadData:exercise_frequency"
              label="Pratica alguma atividade física? E com qual frequência?"
              type="select"
              handleInputChange={(event) => this.handleInputChange(event)}
              value={this.state.leadData.exercise_frequency}
              options={this.getExerciseFrequencyOptions()}
              disabled={this.state.sendingContact}
            />

            {this.state.showExerciseDescriptionInput &&
              <DefaultInput
                labelClassName="main-page__contact-section__input-label"
                className="main-page__contact-section__input"
                name="leadData:exercise_activity"
                label="Quais exercícios pratica?"
                type="text"
                isHighlighted={this.state.highlights.includes('leadData:exercise_activity')}
                placeholder="Ex: caminhada, futebol, musculação, etc"
                maxLength="256"
                handleInputChange={(event) => this.handleInputChange(event)}
                value={this.state.leadData.exercise_activity}
                autoComplete="off"
                disabled={this.state.sendingContact}
              />
            }

            <div className="main-page__contact-section__discount-field__container">

              {this.state.loadingPromotionalCoupon ? (
                <LoadingIcon className="main-page__contact-section__discount-field__loading-icon"/>
              ) : (
                <React.Fragment>

                  <div className="main-page__contact-section__discount-field__wrapper">

                    <DefaultInput
                      labelClassName="main-page__contact-section__input-label"
                      className="main-page__contact-section__input main-page__contact-section__discount-field__input"
                      name="promotional_coupon_code"
                      label="Cupom de desconto"
                      type="text"
                      placeholder="Código do cupom"
                      maxLength="32"
                      handleInputChange={(event) => this.handleInputChange(event)}
                      value={this.state.promotional_coupon_code}
                      autoComplete="off"
                    />

                    <button
                      className="main-page__contact-section__discount-field__apply-button"
                      disabled={this.state.promotional_coupon_code.length <= 0}
                      onClick={() => this.onApplyDiscountCoupon()}
                    >

                      Aplicar

                    </button>

                  </div>

                  <WarningMessage
                    className="main-page__contact-section__discount-field__result-message"
                    message={this.state.promotionalCouponApplied !== null ? `${100*this.state.promotionalCouponApplied.discount_percentage}% de desconto aplicado!` : this.state.discountFieldMessage}
                    onClose={() => this.setState({discountFieldMessageVisible: false})}
                    visible={this.state.discountFieldMessageVisible || (this.state.promotionalCouponApplied !== null)}
                    background={this.state.promotionalCouponApplied !== null ? '#d6e5ce' : '#fbe1e3'}
                    color={this.state.promotionalCouponApplied !== null ? '#328643' : '#e73d4a'}
                    hideCloseButton={true}
                  />
                </React.Fragment>
              )}

            </div>

            <DefaultInput
              labelClassName="main-page__contact-section__input-label"
              className="main-page__contact-section__input"
              isHighlighted={this.state.highlights.includes('payment_option')}
              name="leadData:payment_option"
              label="Selecione a forma desejada de pagamento:"
              labelMessage={this.state.promotionalCouponApplied !== null ? `Cupom aplicado: ${this.state.promotionalCouponApplied.code}` : null}
              type="select"
              handleInputChange={(event) => this.handleInputChange(event)}
              value={this.state.leadData.payment_option}
              options={this.getPaymentOptions(true)}
              disabled={this.state.sendingContact}
            />

          </React.Fragment>
        }

      </React.Fragment>
    );
  }

  getContactButtonText() {
    if (this.state.sendingContact) {
      return 'Enviando...';
    }
    else if (!this.state.registrationIsInactive && this.state.serviceRequest.unit_id !== '0') {
      return 'Cadastrar interesse';
    }

    return 'Registrar interesse';
  }

  async onSendContactRegistration() {
    const highlights = [];
    const messages = [];

    if (!this.state.registrationIsInactive && this.state.serviceRequest.unit_id !== '0') {
      if(this.state.serviceRequest.unit_id.length <= 0) {
        highlights.push('unit_id');
        messages.push('Unidade não selecionada');
      }

      if(this.state.serviceRequest.email.length <= 0) {
        highlights.push('email');
        messages.push('E-mail não preencido');
      }
      else if(this.state.serviceRequest.email.match(/.+@.+\..+/) === null) {
        highlights.push('email');
        messages.push('Formato de e-mail inválido');
      }

      if(this.state.serviceRequest.payment_option.length <= 0) {
        highlights.push('payment_option');
        messages.push('Forma de pagamento não selecionada');
      }
    }
    else {
      if(this.state.leadData.name.length <= 0) {
        highlights.push('leadData:name');
        messages.push('E-mail não preencido');
      }

      if(this.state.leadData.email.length <= 0) {
        highlights.push('leadData:email');
        messages.push('E-mail não preencido');
      }
      else if(this.state.leadData.email.match(/.+@.+\..+/) === null) {
        highlights.push('leadData:email');
        messages.push('Formato de e-mail inválido');
      }

      if(this.state.leadData.phone.length <= 0) {
        highlights.push('leadData:phone');
        messages.push('Telefone de contato não preencido');
      }
      else if(this.state.leadData.phone.length < 10) {
        highlights.push('leadData:phone');
        messages.push('Número de telefone inválido');
      }

      if(this.state.leadData.main_objective.length <= 0) {
        highlights.push('leadData:main_objective');
        messages.push('Objetivo não selecionado');
      }

      if(this.state.leadData.exercise_frequency.length <= 0) {
        highlights.push('leadData:exercise_frequency');
        messages.push('Frequência de exercício não selecionada');
      }

      if(this.state.showExerciseDescriptionInput && this.state.leadData.exercise_activity.length <= 0) {
        highlights.push('leadData:exercise_activity');
        messages.push('Por favor, mencione os exercícios que você normalmente pratica');
      }

      if(this.state.leadData.payment_option.length <= 0) {
        highlights.push('payment_option');
        messages.push('Forma de pagamento não selecionada');
      }
    }

    if(highlights.length > 0) {
      this.setState({
        highlights,
        showContactMessage: true,
        contactMessage: `${messages.join('. ')}.`
      });
    }
    else {
      this.setState({
        highlights: [],
        sendingContact: true,
        contactMessage: "",
        showContactMessage: false
      });

      let data;
      let path;
      let requestTypeText;

      if (!this.state.registrationIsInactive && this.state.serviceRequest.unit_id !== '0') {
        data = {...this.state.serviceRequest};
        path = routes.STUDENT_SERVICE_REQUEST_POST_API;

        data.unit_id = parseInt(data.unit_id);
        data.payment_option = parseInt(data.payment_option);

        requestTypeText = '';

        requestTypeText = 'Contract request';

        if (this.state.promotionalCouponApplied !== null) {
          data.promotional_coupon_id = this.state.promotionalCouponApplied.id;
        }
        else {
          data.promotional_coupon_id = null;
        }
      }
      else {
        data = {...this.state.leadData};
        data.payment_option = parseInt(data.payment_option);

        path = routes.SERVICE_LEAD_POST_API;

        requestTypeText = 'Lead request';

        if (this.state.promotionalCouponApplied !== null) {
          data.promotional_coupon_code = this.state.promotionalCouponApplied.code;
        }
        else {
          data.promotional_coupon_code = null;
        }
      }

      try {
        const response = await postModel(path, data, true);

        if(response) {
          if (process.env.NODE_ENV === 'production') {
            window.gtag('event', 'Request service - sent', {
              'event_category' : 'Request',
              'event_label' : requestTypeText
            });
          }

          this.setState({
            sendingContact: false,
            showContactMessage: false,
            contactCompleted: true,
            contactMessage: response.text,
          });
        }
      }
      catch(errors) {
        let highlights = [];
        let update = {
          contactMessage: DEFAULT_ERROR_MESSAGE,
          showContactMessage: true
        };

        if(errors instanceof Array) {
          for(let error of errors) {
            switch (error.code) {
              case 208:
                if (error.message.includes('User not registered')) {
                  update.contactMessage = 'Não possível encontrar seu cadastro na unidade selecionada. Por favor, certifique-se de que digitou seus dados corretamente.';
                  highlights.push('unit_id');
                  highlights.push('email');
                }
                else if (error.message.includes('Inactive user')) {
                  update = {
                    registrationIsInactive: true,
                    showContactMessage: false
                  };

                  if (!this.state.registrationIsInactive && this.state.serviceRequest.unit_id !== '0') {
                    update.leadData = {...this.state.leadData};
                    update.leadData.email = this.state.serviceRequest.email;
                  }
                }

                break;
              default:
            }
          }
        }

        this.setState({
          highlights,
          sendingContact: false,
          ...update
        });

        window.scrollTo(0, 0);

        return;
      }
    }
  }

  render() {
    return (
      <React.Fragment>

        <MainHeader expanded={this.mainPageElement === null || this.state.scrollPosition === null || this.state.scrollPosition <= 0}/>

        <main className="main-page" ref={this.mainPageRef}>

          <section className="main-page__main-banner" id="inicio">

            <div className="main-page__main-banner__content">

              <div className="main-page__main-banner__content-wrapper">

                <h1 className="main-page__main-banner__header">Plano nutricional<span className="main-page__main-banner__header__strong-text"></span></h1>

                <p className="main-page__main-banner__specifier">Integrado com os treinos da FYDCLUB</p>

                <div className="main-page__main-banner__description-container">

                  <p className="main-page__main-banner__description__title">Plano de 6 semanas</p>

                  <ul className="main-page__main-banner__description__list">

                    <li className="main-page__main-banner__description__list__item">

                      <img className="main-page__main-banner__description__list__item__icon" src={checkIcon} alt="" />
                      <p className="main-page__main-banner__description__list__item__text">
                        Consulta com nutricionista
                      </p>

                    </li>

                    <li className="main-page__main-banner__description__list__item">

                      <img className="main-page__main-banner__description__list__item__icon" src={checkIcon} alt="" />
                      <p className="main-page__main-banner__description__list__item__text">
                        <span className="main-page__main-banner__description__list__item__text--highlight">2</span> Avaliações físicas completas
                      </p>

                    </li>
                    <li className="main-page__main-banner__description__list__item">

                      <img className="main-page__main-banner__description__list__item__icon" src={checkIcon} alt="" />
                      <p className="main-page__main-banner__description__list__item__text">
                        Dieta <span className="main-page__main-banner__description__list__item__text--highlight">personalizada</span>
                      </p>

                    </li>
                    <li className="main-page__main-banner__description__list__item">

                      <img className="main-page__main-banner__description__list__item__icon" src={checkIcon} alt="" />
                      <p className="main-page__main-banner__description__list__item__text">Suporte para dúvidas e orientações</p>

                    </li>
                    <li className="main-page__main-banner__description__list__item">

                      <img className="main-page__main-banner__description__list__item__icon" src={checkIcon} alt="" />
                      <p className="main-page__main-banner__description__list__item__text">Integrado com os treinos</p>

                    </li>
                    <li className="main-page__main-banner__description__list__item">

                      <img className="main-page__main-banner__description__list__item__icon" src={checkIcon} alt="" />
                      <p className="main-page__main-banner__description__list__item__text">
                        Relatórios diários pelo app <span className="main-page__main-banner__description__list__item__text--highlight">FYDCLUB</span>
                      </p>

                    </li>

                  </ul>

                  <a
                    className="main-page__main-banner__description__action-button"
                    href="https://youtu.be/0shaoqlPZTQ"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => {
                      if (process.env.NODE_ENV === 'production') {
                        window.gtag('event', 'Click', {
                          'event_category' : 'Video link',
                          'event_label' : 'https://youtu.be/0shaoqlPZTQ'
                        });
                      }
                    }}
                  >
                    <i className="fa-solid fa-play main-page__main-banner__description__action-button__icon"></i>
                    {this.state.screenWidth > 380 ? 'Mais detalhes do plano' : 'Mais detalhes'}
                  </a>

                </div>

                <div className="main-page__main-banner__price-wrapper">

                  <img className="main-page__main-banner__price-decorator"
                    src={price_decorator}
                    srcSet={`${price_decorator_smaller} 90w,
                             ${price_decorator_smaller_2x} 180w,
                             ${price_decorator_small} 1250w,
                             ${price_decorator_small_2x} 250w,
                             ${price_decorator} 140w,
                             ${price_decorator_2x} 280w`}
                    sizes="(max-width: 600px) 90px, (max-width: 1200px) 125px, 140px"
                    alt=""
                  />

                  <p className="main-page__main-banner__price">

                    R$<br/>
                    <span className="main-page__main-banner__price__multiplier">3x</span>
                    <span className="main-page__main-banner__price__value">72,00</span>

                  </p>

                </div>

              </div>

            </div>

            <div className="main-page__main-banner__images-container">

              {/* <div className="main-page__main-banner__background"></div> */}
              <div className="main-page__main-banner__background-detail--1"></div>
              <div className="main-page__main-banner__background-detail--2"></div>
              <img className="main-page__main-banner__decoration-image--3"
                src={main_banner_decorator_3}
                srcSet={`${main_banner_decorator_3_smaller} 175w,
                         ${main_banner_decorator_3_smaller_2x} 350w,
                         ${main_banner_decorator_3_small} 190w,
                         ${main_banner_decorator_3_small_2x} 380w,
                         ${main_banner_decorator_3} 230w,
                         ${main_banner_decorator_3_2x} 460w`}
                sizes="(max-width: 600px) 175px, (max-width: 870px) 230px, (max-width: 1080px) 175px, (max-width: 1200px) 190px, 230px"
                alt=""
              />
              <img className="main-page__main-banner__decoration-image--1"
                src={main_banner_decorator_1_large}
                srcSet={`${main_banner_decorator_1_tiny} 235w,
                         ${main_banner_decorator_1_tiny_2x} 470w,
                         ${main_banner_decorator_1_smaller} 270w,
                         ${main_banner_decorator_1_smaller_2x} 540w,
                         ${main_banner_decorator_1_small} 340w,
                         ${main_banner_decorator_1_small_2x} 680w,
                         ${main_banner_decorator_1} 405w,
                         ${main_banner_decorator_1_2x} 810w,
                         ${main_banner_decorator_1_large} 450w,
                         ${main_banner_decorator_1_large_2x} 900w`}
                sizes="(max-width: 380px) 235px, (max-width: 470px) 270px, (max-width: 600px) 340px, (max-width: 870px) 405px, (max-width: 1080px) 340px, (max-width: 1320px) 405px, 450px"
                alt=""
              />
              <img className="main-page__main-banner__decoration-image--2"
                src={main_banner_decorator_2_large}
                srcSet={`${main_banner_decorator_2_tiny} 170w,
                         ${main_banner_decorator_2_tiny_2x} 340w,
                         ${main_banner_decorator_2_smaller} 195w,
                         ${main_banner_decorator_2_smaller_2x} 390w,
                         ${main_banner_decorator_2_small} 240w,
                         ${main_banner_decorator_2_small_2x} 480w,
                         ${main_banner_decorator_2} 290w,
                         ${main_banner_decorator_2_2x} 580w,
                         ${main_banner_decorator_2_large} 320w,
                         ${main_banner_decorator_2_large_2x} 640w`}
                sizes="(max-width: 380px) 170px, (max-width: 470px) 195px, (max-width: 600px) 240px, (max-width: 870px) 290px, (max-width: 1080px) 240px, (max-width: 1320px) 290px, 320px"
                alt=""
              />

              <div className="main-page__main-banner__image-wrapper--1">
                <img
                  className="main-page__main-banner__image"
                  src={main_banner_1}
                  srcSet={`${main_banner_1_tiny} 150w,
                           ${main_banner_1_tiny_2x} 300w,
                           ${main_banner_1_smaller} 180w,
                           ${main_banner_1_smaller_2x} 360w,
                           ${main_banner_1_small} 230w,
                           ${main_banner_1_small_2x} 460w,
                           ${main_banner_1} 280w,
                           ${main_banner_1_2x} 560w`}
                  sizes="(max-width: 380px) 150px, (max-width: 470px) 180px, (max-width: 600px) 230px, (max-width: 870px) 280px, (max-width: 1200px) 230px, 280px"
                  alt=""
                />
              </div>
              <div className="main-page__main-banner__image-wrapper--2">
                <img
                  className="main-page__main-banner__image"
                  src={main_banner_2}
                  srcSet={`${main_banner_2_tiny} 150w,
                           ${main_banner_2_tiny_2x} 300w,
                           ${main_banner_2_smaller} 180w,
                           ${main_banner_2_smaller_2x} 360w,
                           ${main_banner_2_small} 230w,
                           ${main_banner_2_small_2x} 460w,
                           ${main_banner_2} 280w,
                           ${main_banner_2_2x} 560w`}
                  sizes="(max-width: 380px) 150px, (max-width: 470px) 180px, (max-width: 600px) 230px, (max-width: 870px) 280px, (max-width: 1200px) 230px, 280px"
                  alt=""
                />
              </div>
              <div className="main-page__main-banner__image-wrapper--3">
                <img
                  className="main-page__main-banner__image"
                  src={main_banner_3}
                  srcSet={`${main_banner_3_tiny} 150w,
                           ${main_banner_3_tiny_2x} 300w,
                           ${main_banner_3_smaller} 180w,
                           ${main_banner_3_smaller_2x} 360w,
                           ${main_banner_3_small} 230w,
                           ${main_banner_3_small_2x} 460w,
                           ${main_banner_3} 280w,
                           ${main_banner_3_2x} 560w`}
                  sizes="(max-width: 380px) 150px, (max-width: 470px) 180px, (max-width: 600px) 230px, (max-width: 870px) 280px, (max-width: 1200px) 230px, 280px"
                  alt=""
                />
              </div>

            </div>

          </section>

          <section className="main-page__separator-section">

            <div className="main-page__separator-section__content">

              <article className="main-page__separator-section__article">

                <div className="main-page__separator-section__article__content-wrapper">

                  <h2 className="main-page__separator-section__article__title">
                    A FYDCLUB em parceria com nutricionistas oferece:
                  </h2>

                  <p className="main-page__separator-section__article__text">

                    <span className="main-page__separator-section__article__text__group--borded">

                      <span className="main-page__separator-section__article__text__subgroup--underlined">Treino planejado</span>
                      <span className="main-page__separator-section__article__text__subgroup">+</span>
                      <span className="main-page__separator-section__article__text__subgroup--underlined">Plano alimentar</span>
                    </span>

                    <span className="main-page__separator-section__article__text__group">

                      =

                    </span>


                    <span className="main-page__separator-section__article__text__group--borded">

                      <span className="main-page__separator-section__article__text__subgroup--underlined">

                        Resultado

                      </span>

                    </span>

                  </p>

                </div>

                <a
                  className="main-page__separator-section__action-button"
                  href="#contratar"
                  // onClick={() => {
                  // }}
                >
                  Comprar agora
                </a>

              </article>

            </div>

          </section>

          <section className="main-page__about-section" id="sobre">

            <article className="main-page__about-section__content">

              <div className="main-page__about-section__header">

                <h2 className="main-page__about-section__title">
                  <span className="main-page__about-section__title--small">Sobre o nosso</span><br/>
                  PLANO NUTRICIONAL
                </h2>

                <p className="main-page__about-section__description">

                  Quer emagrecer ou ganhar massa magra? Seja qual for o seu objetivo, nosso serviço oferece todas as ferramentas necessárias
                  para que você os conquiste de forma planejada e prática. Veja abaixo as etapas principais que você passará caso contrate nosso serviço:

                </p>

              </div>

              <ul className="main-page__about-section__steps">

                <div className="main-page__about-section__step-row--1">

                  <li className="main-page__about-section__step--1">

                    <div className="main-page__about-section__step__header">

                      <h4 className="main-page__about-section__step__header__title">Pré</h4>
                      <h5 className="main-page__about-section__step__header__subtitle">Av. nutricional</h5>

                    </div>

                    <div className="main-page__about-section__step__content">

                      <div className="main-page__about-section__step__image-wrapper">

                        <img
                          className="main-page__about-section__step__image-detail"
                          src={about_section_step_image_background}
                          alt=""
                        />

                        <img
                          className="main-page__about-section__step__image"
                          src={about_section_step_1}
                          srcSet={`${about_section_step_1} 120w,
                                   ${about_section_step_1_2x} 240w`}
                          sizes="120px"
                          alt=""
                        />

                        <img className="main-page__about-section__step__icon" src={about_section_step_icon_1} alt="" />

                      </div>

                      <div className="main-page__about-section__step__description">

                        <img
                          className="main-page__about-section__step__description__background"
                          src={about_section_step_description_background}
                          alt=""
                        />

                        <p className="main-page__about-section__step__description__text">

                          Aqui, você nos informa em detalhes como se encontra sua dieta atualmente
                          e quais são suas restrições alimentares.

                        </p>

                      </div>

                    </div>

                  </li>

                  <li className="main-page__about-section__step--2">

                    <div className="main-page__about-section__step__header">

                      <h4 className="main-page__about-section__step__header__title">Pré</h4>
                      <h5 className="main-page__about-section__step__header__subtitle">Avaliação física</h5>

                    </div>

                    <div className="main-page__about-section__step__content">

                      <div className="main-page__about-section__step__image-wrapper">

                        <img
                          className="main-page__about-section__step__image-detail"
                          src={about_section_step_image_background}
                          alt=""
                        />

                        <img
                          className="main-page__about-section__step__image"
                          src={about_section_step_2}
                          srcSet={`${about_section_step_2} 120w,
                                   ${about_section_step_2_2x} 240w`}
                          sizes="120px"
                          alt=""
                        />

                        <img className="main-page__about-section__step__icon" src={about_section_step_icon_2} alt="" />

                      </div>

                      <div className="main-page__about-section__step__description">

                        <img
                          className="main-page__about-section__step__description__background"
                          src={about_section_step_description_background}
                          alt=""
                        />

                        <p className="main-page__about-section__step__description__text">

                          Um levantamento completo de sua situação física, com peso,
                          medidas de circunferência, fotos e composição corporal.

                        </p>

                      </div>

                    </div>

                  </li>

                </div>

                <div className="main-page__about-section__step-row--2">

                  <li className="main-page__about-section__step--3">

                    <div className="main-page__about-section__step__header">

                      <h4 className="main-page__about-section__step__header__title">Pré</h4>
                      <h5 className="main-page__about-section__step__header__subtitle">Prescrição</h5>

                    </div>

                    <div className="main-page__about-section__step__content">

                      <div className="main-page__about-section__step__image-wrapper">

                        <img
                          className="main-page__about-section__step__image-detail"
                          src={about_section_step_image_background}
                          alt=""
                        />

                        <img
                          className="main-page__about-section__step__image"
                          src={about_section_step_3}
                          srcSet={`${about_section_step_3} 120w,
                                   ${about_section_step_3_2x} 240w`}
                          sizes="120px"
                          alt=""
                        />

                        <img className="main-page__about-section__step__icon" src={about_section_step_icon_1} alt="" />

                      </div>

                      <div className="main-page__about-section__step__description">

                        <img
                          className="main-page__about-section__step__description__background"
                          src={about_section_step_description_background}
                          alt=""
                        />

                        <p className="main-page__about-section__step__description__text">

                          Uma consulta com o nutricionista para fornecer as orientações sobre a dieta, atividade física e
                          uso de nosso aplicativo durante o acompanhamento.

                        </p>

                      </div>

                    </div>

                  </li>

                  <li className="main-page__about-section__step--4">

                    <div className="main-page__about-section__step__header">

                      <h4 className="main-page__about-section__step__header__title">1-6ª{this.state.screenWidth > 970 ? (<br/>) : ' '}Sem</h4>
                      <h5 className="main-page__about-section__step__header__subtitle">Acompanhamento</h5>

                    </div>

                    <div className="main-page__about-section__step__content">

                      <div className="main-page__about-section__step__image-wrapper">

                        <img
                          className="main-page__about-section__step__image-detail"
                          src={about_section_step_image_background}
                          alt=""
                        />

                        <img
                          className="main-page__about-section__step__image"
                          src={about_section_step_4}
                          srcSet={`${about_section_step_4} 120w,
                                   ${about_section_step_4_2x} 240w`}
                          sizes="120px"
                          alt=""
                        />

                        <img className="main-page__about-section__step__icon" src={about_section_step_icon_3} alt="" />

                      </div>

                      <div className="main-page__about-section__step__description">

                        <img
                          className="main-page__about-section__step__description__background"
                          src={about_section_step_description_background}
                          alt=""
                        />

                        <p className="main-page__about-section__step__description__text">

                          Nas próximas 6 semanas, você acompanha a evolução de suas metas e objetivos
                          semanais diretamente através do nosso aplicativo FYD.

                        </p>

                      </div>

                    </div>

                  </li>

                </div>

                <div className="main-page__about-section__step-row--3">

                  <li className="main-page__about-section__step--5">

                    <div className="main-page__about-section__step__header">

                      <h4 className="main-page__about-section__step__header__title">Pós</h4>
                      <h5 className="main-page__about-section__step__header__subtitle">Reavaliação física</h5>

                    </div>

                    <div className="main-page__about-section__step__content">

                      <div className="main-page__about-section__step__image-wrapper">

                        <img
                          className="main-page__about-section__step__image-detail"
                          src={about_section_step_image_background}
                          alt=""
                        />

                        <img
                          className="main-page__about-section__step__image"
                          src={about_section_step_5}
                          srcSet={`${about_section_step_5} 120w,
                                   ${about_section_step_5_2x} 240w`}
                          sizes="120px"
                          alt=""
                        />

                        <img className="main-page__about-section__step__icon" src={about_section_step_icon_2} alt="" />

                      </div>

                      <div className="main-page__about-section__step__description">

                        <img
                          className="main-page__about-section__step__description__background"
                          src={about_section_step_description_background}
                          alt=""
                        />

                        <p className="main-page__about-section__step__description__text">

                          Ao fim das 6 semanas, uma nova avaliação física é realizada para levantar
                          quantitativamente sua evolução dentro do período.

                        </p>

                      </div>

                    </div>

                  </li>

                  <li className="main-page__about-section__step--6">

                    <div className="main-page__about-section__step__header">

                      <h4 className="main-page__about-section__step__header__title">Pós</h4>
                      <h5 className="main-page__about-section__step__header__subtitle">Av. de resultados</h5>

                    </div>

                    <div className="main-page__about-section__step__content">

                      <div className="main-page__about-section__step__image-wrapper">

                        <img
                          className="main-page__about-section__step__image-detail"
                          src={about_section_step_image_background}
                          alt=""
                        />

                        <img
                          className="main-page__about-section__step__image"
                          src={about_section_step_6}
                          srcSet={`${about_section_step_6} 120w,
                                   ${about_section_step_6_2x} 240w`}
                          sizes="120px"
                          alt=""
                        />

                        <img className="main-page__about-section__step__icon" src={about_section_step_icon_1} alt="" />

                      </div>

                      <div className="main-page__about-section__step__description">

                        <img
                          className="main-page__about-section__step__description__background"
                          src={about_section_step_description_background}
                          alt=""
                        />

                        <p className="main-page__about-section__step__description__text">

                          Com seus resultados, nossa equipe lhe enviará um parecer sobre sua evolução,
                          podendo também fornecer orientações adicionais para serem seguidas.

                        </p>

                      </div>

                    </div>

                  </li>

                </div>

              </ul>

            </article>

            <div className="main-page__about-section__images-container">

              <div className="main-page__about-section__background-detail--1"></div>
              <div className="main-page__about-section__background-detail--2"></div>

              <img className="main-page__about-section__decoration-image--1" src={about_section_decorator_1} alt="" />

              <div className="main-page__about-section__image-wrapper--1">
                <img
                  className="main-page__about-section__image"
                  src={about_section_1_large}
                  srcSet={`${about_section_1_very_tiny} 130w,
                           ${about_section_1_very_tiny_2x} 260w,
                           ${about_section_1_tiny} 190w,
                           ${about_section_1_tiny_2x} 380w,
                           ${about_section_1_very_small} 240w,
                           ${about_section_1_very_small_2x} 480w,
                           ${about_section_1_small} 290w,
                           ${about_section_1_small_2x} 580w,
                           ${about_section_1} 350w,
                           ${about_section_1_2x} 700w,
                           ${about_section_1_large} 390w,
                           ${about_section_1_large_2x} 780w`}
                  sizes="(max-width: 380px) 130px, (max-width: 470px) 190px, (max-width: 720px) 240px, (max-width: 970px) 290px, (max-width: 1200px) 350px, 390px"
                  alt=""
                />
              </div>
              <div className="main-page__about-section__image-wrapper--2">
                <img
                  className="main-page__about-section__image"
                  src={about_section_2}
                  srcSet={`${about_section_2_tiny} 130w,
                           ${about_section_2_tiny_2x} 260w,
                           ${about_section_2_smaller} 190w,
                           ${about_section_2_smaller_2x} 380w,
                           ${about_section_2_small} 240w,
                           ${about_section_2_small_2x} 480w,
                           ${about_section_2} 290w,
                           ${about_section_2_2x} 580w,
                           ${about_section_2_large} 350w,
                           ${about_section_2_large_2x} 700w`}
                  sizes="(max-width: 380px) 130px, (max-width: 470px) 190px, (max-width: 720px) 240px, (max-width: 1080px) 290px, 350px"
                  alt=""
                />
              </div>

            </div>

          </section>

          <section className="main-page__video-section" id="video">

            <div className="main-page__video-section__content">

              <div className="main-page__video-section__icon">

                <i className="fab fa-youtube"></i>

              </div>

              <h1 className="main-page__video-section__header">

                Vídeo introdutório

              </h1>

              <p className="main-page__video-section__specifier">

                Conheça um pouco mais sobre nosso serviço e entenda em detalhes tudo o que vamos lhe oferecer para que você alcance seus objetivos.

              </p>

              <a
                className="main-page__video-section__trial-link"
                href="https://youtu.be/0shaoqlPZTQ"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => {
                  if (process.env.NODE_ENV === 'production') {
                    window.gtag('event', 'Click', {
                      'event_category' : 'Video section link',
                      'event_label' : 'https://youtu.be/0shaoqlPZTQ'
                    });
                  }
                }}
              >
                Assista ao vídeo
              </a>

            </div>

            <div className="main-page__video-section__images-container">

              <div className="main-page__video-section__background-filter"></div>

              <img
                className="main-page__video-section__main-image"
                src={video_section_background_1000}
                srcSet={`${video_section_background_3200} 3200w,
                         ${video_section_background_2800} 2800w,
                         ${video_section_background_2400} 2400w,
                         ${video_section_background_2000} 2000w,
                         ${video_section_background_1600} 1600w,
                         ${video_section_background_1400} 1400w,
                         ${video_section_background_1200} 1200w,
                         ${video_section_background_1000} 1000w,
                         ${video_section_background_800} 800w,
                         ${video_section_background_700} 700w,
                         ${video_section_background_600} 600w,
                         ${video_section_background_500} 500w,
                         ${video_section_background_400} 400w,
                         ${video_section_background_350} 350w,
                         ${video_section_background_300} 300w`}
                sizes="(max-width: 300px) 300px, (max-width: 350px) 350px, (max-width: 400px) 400px, (max-width: 500px) 500px, (max-width: 600px) 600px, (max-width: 700px) 700px, (max-width: 800px) 800px, (max-width: 1000px) 1000px, (max-width: 1200px) 1200px, (max-width: 1400px) 1400px, (max-width: 1600px) 1600px, (max-width: 2000px) 2000px, (max-width: 2400px) 2400px, (max-width: 2800px) 2800px, 3200px"
                alt=""
              />

            </div>

          </section>

          <section className="main-page__contact-section" id="contratar">

            <div className="main-page__contact-section__content">

              <h2 className="main-page__contact-section__title">

                Tenho <span className="main-page__contact-section__title--highlighted">interesse</span>

              </h2>

              <hr className="main-page__contact-section__hr"></hr>

              {!this.state.contactCompleted &&
                <p className="main-page__contact-section__semi-title">

                  Informe os dados abaixo para receber mais informações sobre nosso serviço.

                </p>
              }

              <div className="main-page__contact-section__form-container">

                <div className="main-page__contact-section__warning-container">

                  <WarningMessage
                    message={this.state.contactMessage}
                    onClose={() => this.setState({highlights: [], showContactMessage: false})}
                    visible={this.state.showContactMessage}
                  />

                </div>

                {this.getFormInputs()}

                {!this.state.contactCompleted &&
                  <button
                    className={`main-page__contact-section__send-form-button${this.state.sendingContact ? '--active' : ''}`}
                    disabled={this.state.sendingContact}
                    onClick={() => {
                      this.onSendContactRegistration();

                      if (process.env.NODE_ENV === 'production') {
                        window.gtag('event', 'Request service', {
                          'event_category' : 'Click',
                          'event_label' : this.getContactButtonText()
                        });
                      }
                    }}
                  >
                    {this.getContactButtonText()}
                  </button>
                }

              </div>

            </div>

            <div className="main-page__contact-section__images-container">

              <div className="main-page__contact-section__background-detail--1"></div>
              <div className="main-page__contact-section__background-detail--2"></div>

            </div>

          </section>

          <MainFooter />

        </main>

        <ChatOverlayButton />

      </React.Fragment>
    );
  }
}

function WrapperMainPage(props) {
  const location = useLocation();

  return (<MainPage location={location} {...props} />);
};

export default WrapperMainPage;
