const DEFAULT_ERROR_MESSAGE = `Infelizmente estamos com problemas temporários em nosso serviço. ` +
                              `Certifique-se de que esteja conectado na internet e, ` +
                              `por favor, tente novamente mais tarde.`;

const DEFAULT_UNKNOWN_ERROR_MESSAGE = `Erro não documentado. Por favor, entre em contato com ` +
                                      `o suporte para que isto seja corrigido`;

export {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_UNKNOWN_ERROR_MESSAGE,
};


const STATE_API = '/request_state';
const STUDENT_SERVICE_REQUEST_POST_API = '/orientacao-nutricional/iniciar-contratacao-de-aluno';
const SERVICE_LEAD_POST_API = '/orientacao-nutricional/registrar-interesse';
const CREATE_SERVICE_CONTRACT_POST_BY_HASH = '/orientacao-nutricional/confirmar-contratacao/';
const CHECK_DISCOUNT_COUPON_POST_API = '/orientacao-nutricional/validar-cupom-de-desconto';

export {
  STATE_API,
  STUDENT_SERVICE_REQUEST_POST_API,
  SERVICE_LEAD_POST_API,
  CREATE_SERVICE_CONTRACT_POST_BY_HASH,
  CHECK_DISCOUNT_COUPON_POST_API
};


const MAIN_PATH = '/';
const CONFIRM_CONTRACT_CREATION_PATH = '/confirmar-contratacao/';
const THANK_YOU_PATH = '/obrigado';
const BLANK_PATH = '/blank';

export {
  MAIN_PATH,
  CONFIRM_CONTRACT_CREATION_PATH,
  THANK_YOU_PATH,
  BLANK_PATH
};
