import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './main_header.scss'
import logo from '../images/fyd_logo.svg';
import * as routes from '../constants';

class MainHeader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      screenWidth: window.innerWidth,
      menuVisible: false
    };
  }

  async componentDidMount() {
    this.resizeListener = () => this.updateSize();
    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  getNavLink(text, path, isExternal=false) {
    if(this.props.location.pathname.startsWith(path)) {
      return (
        <li className="main-header__navigation__link-wrapper--selected">
          <p className="main-header__navigation__link--selected">{text}</p>
        </li>
      );
    }

    if(isExternal) {
      return (
        <li className="main-header__navigation__link-wrapper">
          <a
            href={path}
            className="main-header__navigation__link"
            onClick={() => {
              if (process.env.NODE_ENV === 'production') {
                window.gtag('event', `Header - ${text}`, {
                  'event_category' : 'Click',
                  'event_label' : text
                });
              }
            }}
          >
            {text}
          </a>
        </li>
      );
    }
    else {
      return (
        <li className="main-header__navigation__link-wrapper">
          <Link
            className="main-header__navigation__link"
            to={path}
            onClick={() => {
              if (process.env.NODE_ENV === 'production') {
                window.gtag('event', `Header - ${text}`, {
                  'event_category' : 'Click',
                  'event_label' : text
                });
              }
            }}
          >
            {text}
          </Link>
        </li>
      );
    }
  }

  render() {
    if(this.state.screenWidth <= 750) {
      return (
        <React.Fragment>

          <header className={`main-header${(this.props.expanded && !this.state.menuVisible) ? '--expanded' : ''}`}>

            <div className="main-header__wrapper">

              <div className="main-header__controls-container">

                <Link
                  className="main-header__logo"
                  to={routes.MAIN_PATH}
                  onClick={() => {
                    if (process.env.NODE_ENV === 'production') {
                      window.gtag('event', 'Header - Logo', {
                        'event_category' : 'Click',
                        'event_label' : 'FYD logo'
                      });
                    }
                  }}
                >

                  <img className="main-header__logo__image" src={logo} alt="Logo da FYD" />
                  <div className="main-header__logo__text-container">
                    <h1 className="main-header__logo__text">FYD CLUB</h1>
                    <h2 className="main-header__logo__subtext">Nutrição</h2>
                  </div>

                </Link>

                {!this.props.collapse &&
                  <button
                    className="main-header__menu-toggler"
                    type="button"
                    onClick={() => this.setState({menuVisible: !this.state.menuVisible})}
                  >
                    <i className="fas fa-bars"></i>
                  </button>
                }

              </div>

              {!this.props.collapse &&
                <React.Fragment>

                  <div className={`main-header__toggle-container${this.state.menuVisible ? '--visible' : ''}`}>

                    <nav className="main-header__navigation">

                      <ul className="main-header__navigation__links-container">

                        {this.getNavLink('Início', '#inicio', true)}
                        {this.getNavLink('Sobre', '#sobre', true)}
                        {this.getNavLink('Vídeo explicativo', '#video', true)}
                        {this.getNavLink('Contratar', '#contratar', true)}

                      </ul>

                    </nav>

                  </div>

                  <aside className="main-header__social-media-container">

                    <ul className="main-header__navigation__social-links-container">

                      {/* <li className="main-header__navigation__social-link-wrapper">
                        <a
                          className="main-header__navigation__social-link"
                          href="https://www.facebook.com/fyd.centrotreinamento"
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={() => {
                            if (process.env.NODE_ENV === 'production') {
                              window.gtag('event', 'Header - Facebook', {
                                'event_category' : 'Click',
                                'event_label' : 'Facebook'
                              });
                            }
                          }}
                        >
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>

                      <li className="main-header__navigation__social-link-wrapper">
                        <a
                          className="main-header__navigation__social-link"
                          href="https://www.instagram.com/fyd_clubsc/"
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={() => {
                            if (process.env.NODE_ENV === 'production') {
                              window.gtag('event', 'Header - Instagram', {
                                'event_category' : 'Click',
                                'event_label' : 'Instagram'
                              });
                            }
                          }}
                        >
                          <i className="fab fa-instagram"></i>
                        </a>
                      </li> */}

                      <li className="main-header__navigation__social-link-wrapper">
                        <a
                          className="main-header__navigation__social-link"
                          href="https://wa.me/5516997475660"
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={() => {
                            if (process.env.NODE_ENV === 'production') {
                              window.gtag('event', 'Header - Whatsapp', {
                                'event_category' : 'Click',
                                'event_label' : 'Whatsapp'
                              });
                            }
                          }}
                        >
                          <i className="fab fa-whatsapp"></i>
                        </a>
                      </li>

                    </ul>

                  </aside>

                </React.Fragment>
              }

            </div>

          </header>

          <div
            className={`main-header__toggle-background-block${this.state.menuVisible ? '--visible' : ''}`}
            onClick={() => this.setState({menuVisible: !this.state.menuVisible})}
          ></div>

        </React.Fragment>
      );
    }

    return (
      <React.Fragment>

        <header className={`main-header${this.props.expanded ? '--expanded' : ''}`}>

          <div className="main-header__wrapper">

            <Link
              className="main-header__logo"
              to={routes.MAIN_PATH}
              onClick={() => {
                // if (process.env.NODE_ENV === 'production') {
                //   window.gtag('event', 'Header - Logo', {
                //     'event_category' : 'Click',
                //     'event_label' : 'FYD logo'
                //   });
                // }
              }}
            >

              <img className="main-header__logo__image" src={logo} alt="Logo da FYD" />
              <div className="main-header__logo__text-container">
                <h1 className="main-header__logo__text">FYD CLUB</h1>
                <h2 className="main-header__logo__subtext">Nutrição</h2>
              </div>

            </Link>


            {!this.props.collapse &&
              <div className="main-header__controls-container">

                <nav className="main-header__navigation">

                  <ul className="main-header__navigation__links-container">

                    {this.getNavLink('Início', '#inicio', true)}
                    {this.getNavLink('Sobre', '#sobre', true)}
                    {this.getNavLink('Vídeo explicativo', '#video', true)}
                    {this.getNavLink('Contratar', '#contratar', true)}

                  </ul>

                </nav>

                <aside className="main-header__social-media-container">

                  <ul className="main-header__navigation__social-links-container">

                    {/* <li className="main-header__navigation__social-link-wrapper">
                      <a
                        className="main-header__navigation__social-link"
                        href="https://www.facebook.com/fyd.centrotreinamento"
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => {
                          if (process.env.NODE_ENV === 'production') {
                            window.gtag('event', 'Header - Facebook', {
                              'event_category' : 'Click',
                              'event_label' : 'Facebook'
                            });
                          }
                        }}
                      >
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>

                    <li className="main-header__navigation__social-link-wrapper">
                      <a
                        className="main-header__navigation__social-link"
                        href="https://www.instagram.com/fyd_clubsc/"
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => {
                          if (process.env.NODE_ENV === 'production') {
                            window.gtag('event', 'Header - Instagram', {
                              'event_category' : 'Click',
                              'event_label' : 'Instagram'
                            });
                          }
                        }}
                      >
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li> */}

                    <li className="main-header__navigation__social-link-wrapper">
                      <a
                        className="main-header__navigation__social-link"
                        href="https://wa.me/5516997475660"
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => {
                          if (process.env.NODE_ENV === 'production') {
                            window.gtag('event', 'Header - Whatsapp', {
                              'event_category' : 'Click',
                              'event_label' : 'Whatsapp'
                            });
                          }
                        }}
                      >
                        <i className="fab fa-whatsapp"></i>
                      </a>
                    </li>

                  </ul>

                </aside>

              </div>
            }

          </div>

        </header>

        {this.props.enablePromotion && (
          <aside className={`main-header__promotion${this.props.invertPromotionColors ? '--inverted-colors' : ''}`}>

            <div className="main-header__promotion__wrapper">

              <h2 className="main-header__promotion__title"><span className="main-header__promotion__title--yellow">PROMOÇÃO</span> BLACK <span className="main-header__promotion__title--emphasis">NOVE</span>mber</h2>

              <a
                href="https://promo.fydcentrodetreinamento.com.br"
                target="_blank"
                rel="noopener noreferrer"
                className="main-header__promotion__link-button"
                onClick={() => {
                  // if (process.env.NODE_ENV === 'production') {
                  //   window.gtag('event', 'Header - Botão de promoção - Black november', {
                  //     'event_category' : 'Click',
                  //     'event_label' : 'Black november - saiba mais'
                  //   });
                  // }
                }}
              >
                Saiba mais
              </a>

            </div>

          </aside>
        )}

      </React.Fragment>
    );
  }
}

function WrapperMainHeader(props) {
  const location = useLocation();

  return (<MainHeader location={location} {...props} />);
};

export default WrapperMainHeader;
