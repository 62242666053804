import React from 'react';
import './warning_message.scss';

class WarningMessage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };

    this.containerElement = null;
    this.containerRef = (element) => {
      if (element == null) {
        this.containerElement = null;
      }
      else {
        this.containerElement = element;

        if (this.props.visible) {
          this.containerElement.style.height = 'auto';
        }
        else {
          this.containerElement.style.height = '0';
        }
      }
    };
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.visible !== this.props.visible || prevProps.message !== this.props.message) {
      if (this.containerElement !== null) {
        if (this.props.visible) {
          const previousHeight = this.containerElement.getBoundingClientRect().height;

          this.containerElement.style.transition = '';
          this.containerElement.style.height = 'auto';

          requestAnimationFrame(() => {
            const nextHeight = this.containerElement.getBoundingClientRect().height;

            this.containerElement.style.transition = 'all 0.3s cubic-bezier(0.22, 0.97, 0.58, 1)';
            this.containerElement.style.height = `${previousHeight}px`;

            requestAnimationFrame(() => {
              this.containerElement.style.height = `${nextHeight}px`;
            });
          });
        }
        else {
          this.containerElement.style.transition = 'all 0.3s cubic-bezier(0.22, 0.97, 0.58, 1)';
          this.containerElement.style.height = '0';
        }
      }
    }
  }

  render() {
    const containerStyle = {};
    const textStyle = {};

    if(this.props.background) {
      containerStyle.background = this.props.background;
    }

    if(this.props.color) {
      textStyle.color = this.props.color;
    }

    return (
      <aside
        className={`warning-message${this.props.visible ? '' : '--hidden'} ${this.props.className || ''}`}
        style={containerStyle}
        ref={this.containerRef}
      >

        <p
          className="warning-message__message"
          style={textStyle}
        >

          {this.props.message}

        </p>

        {!this.props.hideCloseButton &&
          <div className="warning-message__control-container">

            <button
              className="warning-message__close-button"
              style={textStyle}
              onClick={() => this.props.onClose()}
            >

              <i className="fas fa-times"></i>

            </button>

          </div>
        }

      </aside>
    );
  }
}

export default WarningMessage;
