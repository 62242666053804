import React from 'react';
import './loading_icon.scss';

import loadingCircle from '../images/loading_circle.svg';
import loadingCircleWhite from '../images/loading_circle_white.svg';

function LoadingIcon(props) {
  return (
    <div className={`loading-icon${props.className ? ` ${props.className}` : ''}`}>

      <p className="loading-icon__text">{props.text || 'Carregando'}</p>
      <img
        className="loading-icon__icon-2"
        src={loadingCircleWhite}
        alt=""
      />
      <img
        className="loading-icon__icon-1"
        src={loadingCircle}
        alt=""
      />

    </div>
  );
}

export default LoadingIcon;
