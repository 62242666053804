import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import MainHeader from '../components/main_header';
import MainFooter from '../components/main_footer';
import LoadingIcon from '../components/loading_icon';
import * as routes from '../constants';
import {postModel} from '../utils/functions';
import './create_contract.scss';

class CreateContract extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingData: true,
      apiFailed: false,
      errorMessage: "",
      userFormLink: ""
    };
  }

  async componentDidMount() {
    try {
      const response = await postModel(`${routes.CREATE_SERVICE_CONTRACT_POST_BY_HASH}${this.props.userHash}`, {}, true);
      if(response) {
        this.setState({
          loadingData: false,
          userFormLink: response.user_registration_link
        });
      }
      else {
        this.setState({
          loadingData: false,
          apiFailed: true,
          errorMessage: 'Infelizmente estamos com problemas temporários em nosso serviço. Por favor, tente novamente mais tarde.'
        });
      }
    }
    catch(errors) {
      let errorMessage = 'Infelizmente estamos com problemas temporários em nosso serviço. Por favor, tente novamente mais tarde.';

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            case 203:
              if (error.message.includes('User does not have permission to perform request')) {
                errorMessage = 'Este link encontra-se já processado ou inválido.';
              }

              break;
            default:
          }
        }
      }

      this.setState({
        loadingData: false,
        apiFailed: true,
        errorMessage
      });
    }
  }

  getContentTitle() {
    if(this.state.loadingData) {
      return (
        <span><span className="create-contract__title--green">Autenticando</span> link</span>
      );
    }
    else if(this.state.apiFailed) {
      return (
        <span className="create-contract__title--green">OOOPS!</span>
      );
    }

    return (
      <span>Contratação <span className="create-contract__title--green">liberada</span></span>
    );
  }

  getContent() {
    if(this.state.apiFailed) {
      return (
        <div
          className="create-contract__alert-text"
        >

          <div className="create-contract__alert-text__icon-wrapper">
            <i className="fas fa-exclamation create-contract__alert-text__icon"></i>
          </div>

          <p className="create-contract__alert-text__text">
            {this.state.errorMessage}
          </p>

        </div>
      );
    }

    return (
      <React.Fragment>

        <div
          className="create-contract__alert-text--green"
        >

          <div className="create-contract__alert-text__icon-wrapper">
            <i className="fa-solid fa-check create-contract__alert-text__icon"></i>
          </div>

          <p className="create-contract__alert-text__text">
            Interesse confirmado com sucesso. Para finalizar o processo de contratação, precisamos agora que você realize o
            preenchimento/confirmação de seus dados cadastrais e realize o aceite de contrato de prestação de serviço. Este procedimento
            pode ser realizado tanto pelo botão abaixo quanto por um link que estaremos lhe enviando por email.
            Desde já um muito obrigado e bons resultados!
          </p>

        </div>

        <a
          className="create-contract__link-button"
          href={this.state.userFormLink}
          target="_self"
          rel="noopener noreferrer"
        >
          Preencher/Confirmar dados cadastrais
        </a>

      </React.Fragment>
    );
  }

  render() {
    return (
      <React.Fragment>

        <MainHeader
          location={this.props.location}
          collapse={true}
        />

        <div className="create-contract__wrapper">

          <section className="create-contract">

            <div className="create-contract__content">

              <h1 className="create-contract__title">{this.getContentTitle()}</h1>

              {this.state.loadingData ?
                <LoadingIcon />:
                this.getContent()
              }

            </div>

          </section>

          <MainFooter
            collapse={true}
          />

        </div>

      </React.Fragment>
    );
  }
}

function WrapperCreateContract(props) {
  const location = useLocation();
  const { userHash } = useParams();

  return (<CreateContract location={location} userHash={userHash} {...props} />);
};

export default WrapperCreateContract;
