import React from 'react';
import MainHeader from '../components/main_header';
import MainFooter from '../components/main_footer';
import {RedText} from '../components/default_elements';
import './thank_you.scss'

function ThankYou(props) {
  return (
    <React.Fragment>

      <MainHeader
        location={props.location}
        collapse={true}
      />

      <div className="thank-you__wrapper">

        <section className="thank-you">

          <div className="thank-you__content">

            <h1 className="thank-you__title">SUCESSO, <RedText>OBRIGADO</RedText></h1>

            <div className="thank-you__text-container">

              <i className="fas fa-check"></i> Todos os dados foram enviados com sucesso.

            </div>

          </div>

        </section>

        <MainFooter
          collapse={true}
        />

      </div>

    </React.Fragment>
  );
  // return (
  //   <FormView
  //     title="Obrigado"
  //     contentTitle={
  //       <React.Fragment>
  //
  //         SUCESSO, <span className="site-red-color">OBRIGADO</span>
  //
  //       </React.Fragment>
  //     }
  //     contentText={
  //       <React.Fragment>
  //
  //         <span className="thank-you__centralized-text-wrapper">
  //
  //           <i className="fas fa-check"></i> Todos os dados foram enviados com sucesso.
  //
  //         </span>
  //
  //       </React.Fragment>
  //     }
  //     questionsId={0}
  //     questions={null}
  //     maxId={0}
  //     showControllers={false}
  //   />
  // );
}

export default ThankYou
