import React from 'react';
import './chat_overlay_button.scss'

class ChatOverlayButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      screenWidth: window.innerWidth
    };
  }

  async componentDidMount() {
    this.resizeListener = () => this.updateSize();
    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  render() {
    return (
      <aside className="chat-overlay-button">

        <a
          className="chat-overlay-button__button"
          href="https://wa.me/5516997475660"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => {
            if (process.env.NODE_ENV === 'production') {
              window.gtag('event', 'Whatsapp overlay button', {
                'event_category' : 'Click',
                'event_label' : 'Whatsapp'
              });
            }
          }}
        >
          <i className="fab fa-whatsapp chat-overlay-button__button__icon"></i>
        </a>

      </aside>
    );
  }
}

export default ChatOverlayButton
